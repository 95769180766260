import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import classes from './Home3.module.scss';
import Typical from 'react-typical'
import { useSpring, animated } from '@react-spring/web'
import { easings } from '@react-spring/web'


// GSAPプラグインの登録
gsap.registerPlugin(ScrollTrigger);

const colors = [
    "rgba(237, 80, 195, 1)",
    "rgba(225, 158, 91, 1)",
    "rgba(218, 93, 114, 1)",
    "rgba(153, 113, 254, 1)",
    "rgba(234, 243, 104, 1)",
    "rgba(159, 241, 147, 1)"
];
const changeColorsToGradient = (...colors) => `radial-gradient(
    at 0% 93%,
    ${colors[0]} 0px,
    transparent 50%
  ),
  radial-gradient(at 10% 38%, ${colors[1]} 0px, transparent 50%),
  radial-gradient(at 85% 57%, ${colors[2]} 0px, transparent 50%),
  radial-gradient(at 60% 11%, ${colors[3]} 0px, transparent 50%),
  radial-gradient(at 48% 83%, ${colors[4]} 0px, transparent 50%),
  radial-gradient(at 94% 97%, ${colors[5]} 0px, transparent 50%)`;

const SectionWipes2 = () => {
    const pinContainerRef = useRef(null);
    const springs = useSpring({
        from: { y: 10, opacity: 0, },
        to: { y: 0, opacity: 1 },
        delay: 1000,
        config: { duration: 1000 }
    })
    const [{ background }, api] = useSpring(() => ({
        background: changeColorsToGradient(...colors)
    }));
    const fade = useSpring({
        from: { opacity: 1 },
        to: { opacity: 0 },
        config: { duration: 2000 },
        delay: 2000,
        loop: false
    });
    const svgAnim = useSpring({
        from: { d: 'M0,100 L50,0 L100,100' }, // 初期パス
        to: [{ d: 'M0,50 L50,100 L100,50' }, { d: 'M0,100 L50,0 L100,100' }],   // 終了パス
        config: { duration: 1000 },
        loop: true
    });
    const arrowAnimation = useSpring({
        from: { transform: 'translate3d(0,-20px,0)', opacity: 0 },
        to: [{ transform: 'translate3d(0,20px,0)', opacity: 1 }, { opacity: 0 }],
        config: {
            duration: 2000, // カスタム持続時間
            tension: 180,   // `wobbly` のテンションに近い値
            friction: 12, easing: easings.easeInOutQuint
        },
        reset: true,
        loop: true
    });
    const moveAnimation = useSpring({
        from: { cx: 20 }, // 開始位置
        to: { cx: 80 },   // 終了位置
        config: { riction: 10, duration: 1000 * 5, easing: easings.easeInOutQuint },
        loop: { reverse: true },
    });
    const rotateAnimation = useSpring({
        loop: true,
        from: { rotateZ: 0 },
        to: { rotateZ: 360 },
        config: { friction: 10, duration: 1000 * 20, easing: easings.easeInOutQuint },
    });

    function shuffleColors() {
        let colors = [
            "rgba(237, 80, 195, 1)",
            "rgba(225, 158, 91, 1)",
            "rgba(218, 93, 114, 1)",
            "rgba(153, 113, 254, 1)",
            "rgba(234, 243, 104, 1)",
            "rgba(159, 241, 147, 1)"
        ];
        let newArr = [];
        let copy = colors.slice(0);
        do {
            let index = Math.floor(Math.random() * copy.length);
            let item = copy[index];
            newArr.push(item);
            copy.splice(index, 1);
        } while (copy.length >= 1);

        api.start({
            background: changeColorsToGradient(...newArr),
            config: { duration: 2000 },
        });
    }

    useEffect(() => {
        const interval = setInterval(shuffleColors, 2000);
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        gsap.timeline({
            scrollTrigger: {
                trigger: pinContainerRef.current,
                pin: true,
                scrub: 1,
                start: "top top",
                end: "+=300%"
            }
        })
        .to({}, {duration: .2})  // 余白の追加
            .from(`.${classes.panel}.${classes.turqoise}`, { x: '-100%' })
            .to({}, {duration: .2})  // 余白の追加
            .from(`.${classes.panel}.${classes.green}`, { x: '100%' })
            .to({}, {duration: .2})  // 余白の追加
            .from(`.${classes.panel}.${classes.bordeaux}`, { y: '-100%' })
            .to({}, {duration: .2})  // 余白の追加
            .from(`.${classes.panel}.${classes.works}`, { y: '100%' })
            .to({}, {duration: .2})  // 余白の追加
            .from(`.${classes.panel}.${classes.contact}`, { x: '-100%' })

        console.log(`.${classes.panel}.${classes.turqoise}`)
        return () => {
            ScrollTrigger.getAll().forEach(st => st.kill())
        };
    }, []);

    return (
        <div className={classes.root}>

            <animated.svg width="50" height="50" viewBox="0 0 50 50" style={arrowAnimation} className={classes.down_arrow}>
                <path d="M10,20 L25,35 L40,20" stroke="black" strokeWidth="1" fill="none" />
            </animated.svg>
            <div className={classes.pinContainer} ref={pinContainerRef}>
                <animated.section className={`${classes.panel} ${classes.blue}`}
                    style={{
                        background: background,
                    }}
                >
                    <span>
                        <animated.div
                            className={classes.en}
                            style={{
                                WebkitTextFillColor: fade.opacity.interpolate(o => `rgba(255, 255, 255, ${o})`),
                                ...springs,
                            }}
                        >
                            Innovate with Us<br />
                            Crafting Digital Experiences

                        </animated.div>
                        <div className={classes.jp} style={{ height: '20vh' }}>
                            私たちと一緒に革新を
                            <Typical
                                steps={['デジタル体験を', 2000, 'デジタル体験を創造する💡', 3500, 'デジタル体験を提案する💬', 3500, 'デジタル体験を切り開く💪', 3500]}
                                loop={Infinity}
                                wrapper="p"
                            />
                        </div>
                        <img className={classes.logo} src="/react-assets/logo.svg" />
                    </span>
                </animated.section>
                <section className={`${classes.panel} ${classes.turqoise}`}>
                    <span>
                        <div className={classes.icon}>
                            <animated.svg
                                style={{
                                    ...rotateAnimation
                                }} width="100" height="100" viewBox="0 0 100 100">
                                <animated.circle
                                    cx={moveAnimation.cx}
                                    cy={50}
                                    r={10}
                                    fill="none"
                                    stroke="white"
                                    strokeWidth=".1"
                                />
                            </animated.svg>

                        </div>
                        <div className={classes.en}>
                            Your Vision, Our Creation<br />
                            Beyond Boundaries
                        </div>
                        <div className={classes.jp}>
                        こんにちは！collabo inc.では、ワクワクするようなWebとアプリの世界を一緒に創り上げていきましょう。私たちはプログラマー、デザイナー、そしてクリエーターの皆さんと共に、新しいアイディアをカタチにしています。
                        </div>
                    </span>
                </section>
                <section className={`${classes.panel} ${classes.green}`}>
                    <span>
                        <div className={classes.icon}>
                            <animated.svg
                                style={{
                                    ...rotateAnimation
                                }}
                                viewBox="0 0 100 100"
                            >
                                <animated.polygon
                                    points="50,10 10,90 90,90"
                                    fill="none"
                                    stroke="white"
                                    strokeWidth=".1"
                                    style={{
                                        transformOrigin: 'center',
                                    }}
                                />
                            </animated.svg>
                        </div>
                        <video src="/react-assets/sample.mp4" autoPlay playsInline muted loop />
                        <div className={classes.en}>
                            Let's Collaborate
                        </div>
                        <div className={classes.jp}>
                        ここでは、技術と創造性が融合し、毎日が新しい発見でいっぱい。あなたのユニークなビジョンを、私たちと一緒に現実にしませんか？楽しみながら、一緒に成長していくことができる、そんな場所です。
                        </div>
                    </span>
                </section>
                <section className={`${classes.panel} ${classes.bordeaux}`}>
                    <div className={classes.icon}>
                        <animated.svg
                            style={{
                                ...rotateAnimation
                            }}
                            viewBox="0 0 100 100"
                        >
                            <animated.rect
                                width={50}
                                height={50}
                                x={25}
                                y={25}
                                fill="none"
                                stroke="white"
                                strokeWidth=".1"
                                style={{
                                    transformOrigin: 'center',
                                }}
                            />
                        </animated.svg>
                    </div>
                    <span>

                        <div className={classes.en}>
                            一緒に創ろう<br />
                            アイデアをつなぎ、未来を創る
                        </div>
                        <div className={classes.jp}>
                        あなたの夢やアイデアを実現するために、私たちはいつでもここにいます。一緒に、面白くて刺激的な未来を作っていきましょう！」
                        </div>
                    </span>
                </section>
                <section className={`${classes.panel} ${classes.works}`}>
                    <div className={classes.icon}>
                        <animated.svg
                            style={{
                                ...rotateAnimation
                            }}
                            viewBox="0 0 100 100"
                        >
                            <animated.rect
                                width={50}
                                height={50}
                                x={25}
                                y={25}
                                fill="none"
                                stroke="white"
                                strokeWidth=".1"
                                style={{
                                    transformOrigin: 'center',
                                }}
                            />
                        </animated.svg>
                    </div>
                    <span>

                        <div className={classes.en}>
                            WORKS
                        </div>
                        <div className={classes.appicon}>
                            <img src="/react-assets/e-plus-icon.svg" alt="p-plus" />
                        </div>
                        <div className={classes.jp}>
                            イートプラス<br />
                            <small>
                                E+（イートプラス）は、キッチンカーおよびテイクアウト店向けのアプリです
                                飲食ビジネスを支えるミッションを掲げ、飲食店の課題を解決するために開発されました。
                            </small>
                        </div>
                    </span>
                </section>
                <section className={`${classes.panel} ${classes.contact}`}>
                    <div className={classes.icon}>
                        <animated.svg
                            style={{
                                ...rotateAnimation
                            }}
                            viewBox="0 0 100 100"
                        >
                            <animated.rect
                                width={50}
                                height={50}
                                x={25}
                                y={25}
                                fill="none"
                                stroke="white"
                                strokeWidth=".1"
                                style={{
                                    transformOrigin: 'center',
                                }}
                            />
                        </animated.svg>
                    </div>
                    <span>

                        <div className={classes.en}>
                            CONTACT
                        </div>
                        <div className={classes.jp}>
                            <p>
                                <b>MAIL</b><br />
                                <small><a href="mailto:sales@collabo.link">sales@collabo.link</a></small>
                            </p>
                            <p>
                                <b>ADDRESS</b><br />
                                <small>〒921-8163 石川県金沢市横川７丁目２６</small>
                            </p>
                            <p>
                                <b>TEL</b><br />
                                <small>076-287-0035</small>
                            </p>
                            <br />
                            <p>
                                <img className={classes.logo} src="/react-assets/logo-gray.svg" />
                            </p>
                        </div>
                    </span>
                </section>
            </div>
        </div>
    );
}

export default SectionWipes2;
