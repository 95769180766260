import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import classes from './Home4.module.scss';
import Typical from 'react-typical'
import { useSpring, animated } from '@react-spring/web'
import { easings } from '@react-spring/web'


// GSAPプラグインの登録
gsap.registerPlugin(ScrollTrigger);

const SectionWipes2 = () => {
    const pinContainerRef = useRef(null);
    const springs = useSpring({
        from: { y: 10, opacity: 0, },
        to: { y: 0, opacity: 1 },
        delay: 1000,
        config: { duration: 1000 }
    })
    
    const fade = useSpring({
        from: { opacity: 1 },
        to: { opacity: 0 },
        config: { duration: 2000 },
        delay: 2000,
        loop: false
    });
    const fadeIn = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: 2000 },
        delay: 2000,
        loop: false
    });
    const svgAnim = useSpring({
        from: { d: 'M0,100 L50,0 L100,100' }, // 初期パス
        to: [{ d: 'M0,50 L50,100 L100,50' }, { d: 'M0,100 L50,0 L100,100' }],   // 終了パス
        config: { duration: 1000 },
        loop: true
    });
    const arrowAnimation = useSpring({
        from: { transform: 'translate3d(0,-20px,0)', opacity: 0 },
        to: [{ transform: 'translate3d(0,20px,0)', opacity: 1 }, { opacity: 0 }],
        config: {
            duration: 2000, // カスタム持続時間
            tension: 180,   // `wobbly` のテンションに近い値
            friction: 12, easing: easings.easeInOutQuint
        },
        reset: true,
        loop: true
    });
    const moveAnimation = useSpring({
        from: { cx: 20 }, // 開始位置
        to: { cx: 80 },   // 終了位置
        config: { riction: 10, duration: 1000 * 5, easing: easings.easeInOutQuint },
        loop: { reverse: true },
    });
    const rotateAnimation = useSpring({
        loop: true,
        from: { rotateZ: 0 },
        to: { rotateZ: 360 },
        config: { friction: 10, duration: 1000 * 20, easing: easings.easeInOutQuint },
    });



    useEffect(() => {
        gsap.timeline({
            scrollTrigger: {
                trigger: pinContainerRef.current,
                pin: true,
                scrub: 1,
                start: "top top",
                end: "+=300%"
            }
        })
            .to({}, {duration: .2})  // 余白の追加
            .from(`.${classes.panel}.${classes.turqoise}`, { x: '-100%' })
            .to({}, {duration: .2})  // 余白の追加
            .from(`.${classes.panel}.${classes.green}`, { x: '100%' })
            .to({}, {duration: .2})  // 余白の追加
            .from(`.${classes.panel}.${classes.bordeaux}`, { y: '-100%' })
            .to({}, {duration: .2})  // 余白の追加
            .from(`.${classes.panel}.${classes.works}`, { y: '100%' })
            .to({}, {duration: .2})  // 余白の追加
            .from(`.${classes.panel}.${classes.contact}`, { x: '-100%' })


        // パララックス効果を追加
        gsap.to(`.${classes.parallax}`, {
            y: (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed,
            ease: 'none',
            scrollTrigger: {
            trigger: pinContainerRef.current,
            start: "top top",
            end: "+=400%",
            scrub: true
            },
        });
        console.log(`.${classes.panel}.${classes.turqoise}`)
        return () => {
            ScrollTrigger.getAll().forEach(st => st.kill())
        };
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.parallax} data-speed="0.5">
                <img src="/react-assets/flutter_logo.svg" />
                <br />
                <img src="/react-assets/400px-Figma-logo2.svg.png" />
                <br />
                <img src="/react-assets/React-icon.svg.png" style={{opacity: .6}} />
            </div>
            <animated.svg width="50" height="50" viewBox="0 0 50 50" style={arrowAnimation} className={classes.down_arrow}>
                <path d="M10,20 L25,35 L40,20" stroke="black" strokeWidth="1" fill="none" />
            </animated.svg>
            <div className={classes.pinContainer} ref={pinContainerRef}>
                <animated.section className={`${classes.panel} ${classes.blue}`}
                    style={{
                        // ...fadeIn,
                    }}
                >
                    <span>
                        <animated.div
                            className={classes.en}
                            style={{
                                WebkitTextFillColor: fade.opacity.interpolate(o => `#00623b`),
                                WebkitTextStrokeColor: fade.opacity.interpolate(o => `rgba(0,0,0,0)`),
                                ...springs,
                            }}
                        >
                            Innovate with Us<br />
                            Crafting Digital Experiences

                        </animated.div>
                        <div className={classes.jp} style={{ height: '20vh' }}>
                            私たちと一緒に革新を
                            <Typical
                                steps={['デジタル体験を', 2000, 'デジタル体験を創造する💡', 3500, 'デジタル体験を提案する💬', 3500, 'デジタル体験を切り開く💪', 3500]}
                                loop={Infinity}
                                wrapper="p"
                            />
                        </div>
                        <img className={classes.logo} src="/react-assets/logo.svg" />
                    </span>
                </animated.section>
                <section className={`${classes.panel} ${classes.turqoise}`}>
                    <span>
                        <div className={classes.icon}>
                            <animated.svg
                                style={{
                                    ...rotateAnimation
                                }} width="100" height="100" viewBox="0 0 100 100">
                                <animated.circle
                                    cx={moveAnimation.cx}
                                    cy={50}
                                    r={10}
                                    fill="none"
                                    stroke="white"
                                    strokeWidth=".1"
                                />
                            </animated.svg>

                        </div>
                        <div className={classes.en}>
                        革新的なアプローチ
                        </div>
                        <div className={classes.jp}>
                        積極的に新しい技術を取り入れた効率と高速なプロダクト開発<br />
                        <small>FigmaやFlutterとReactで、限界を超えたアプリとWeb開発</small>
                        <br /><br />
                        <img className={classes.createapp} src="/react-assets/flutter_logo.svg" />
                        <img className={classes.createapp} src="/react-assets/400px-Figma-logo.svg.png" />
                        <img className={classes.createapp} src="/react-assets/React-icon.svg.png" />
                        </div>
                    </span>
                </section>
                <section className={`${classes.panel} ${classes.green}`}>
                    <span>
                        <div className={classes.icon}>
                            <animated.svg
                                style={{
                                    ...rotateAnimation
                                }}
                                viewBox="0 0 100 100"
                            >
                                <animated.polygon
                                    points="50,10 10,90 90,90"
                                    fill="none"
                                    stroke="white"
                                    strokeWidth=".1"
                                    style={{
                                        transformOrigin: 'center',
                                    }}
                                />
                            </animated.svg>
                        </div>
                        <div className={classes.en}>
                            Connecting Ideas, Creating Future
                        </div>
                        <div className={classes.jp}>
                        時間とコストを最適化し、ビジネスの成長を加速<br />
                        <small>最新技術による迅速開発でコストを削減、効率的なプロジェクト運営を通じてビジネスの成長を加速させます。</small>
                        </div>
                    </span>
                </section>
                <section className={`${classes.panel} ${classes.bordeaux}`}>
                    <div className={classes.icon}>
                        <animated.svg
                            style={{
                                ...rotateAnimation
                            }}
                            viewBox="0 0 100 100"
                        >
                            <animated.rect
                                width={50}
                                height={50}
                                x={25}
                                y={25}
                                fill="none"
                                stroke="white"
                                strokeWidth=".1"
                                style={{
                                    transformOrigin: 'center',
                                }}
                            />
                        </animated.svg>
                    </div>
                    <span>

                        <div className={classes.en}>
                            一緒に創ろう<br />
                            アイデアをつなぎ、未来を創る
                        </div>
                        <div className={classes.jp}>
                            「金沢市から世界へ。私たちは、新しい技術とアイデアで、未来のデジタル体験を創り出します。」
                        </div>
                    </span>
                </section>
                <section className={`${classes.panel} ${classes.contact}`}>
                    <div className={classes.icon}>
                        <animated.svg
                            style={{
                                ...rotateAnimation
                            }}
                            viewBox="0 0 100 100"
                        >
                            <animated.rect
                                width={50}
                                height={50}
                                x={25}
                                y={25}
                                fill="none"
                                stroke="white"
                                strokeWidth=".1"
                                style={{
                                    transformOrigin: 'center',
                                }}
                            />
                        </animated.svg>
                    </div>
                    <span>

                        <div className={classes.en}>
                            CONTACT
                        </div>
                        <div className={classes.jp}>
                            <p>
                                <b>MAIL</b><br />
                                <small><a href="mailto:sales@collabo.link">sales@collabo.link</a></small>
                            </p>
                            <p>
                                <b>ADDRESS</b><br />
                                <small>〒921-8163 石川県金沢市横川７丁目２６</small>
                            </p>
                            <p>
                                <b>TEL</b><br />
                                <small>076-287-0035</small>
                            </p>
                            <br />
                            <p>
                                <img className={classes.logo} src="/react-assets/logo-gray.svg" />
                            </p>
                        </div>
                    </span>
                </section>
            </div>
        </div>
    );
}

export default SectionWipes2;
