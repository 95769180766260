import { BrowserRouter, Switch, } from 'react-router-dom';
import AppLayout from './components/AppLayout';
import Home from './components/Home';
import Home2 from './components/Home2';
import Home3 from './components/Home3';
import Home4 from './components/Home4';
import Home5 from './components/Home5';
import ScrollToTop from './components/lib/ScrollToTop';
import FirebaseAnalytics from './components/lib/FirebaseAnalytics';
import NotFound from './components/NotFound';
import Privacy from './components/Privacy';
import DefaultLayout from './components/DefaultLayout';


function App() {
    return (
        <BrowserRouter>
            <FirebaseAnalytics />
            <ScrollToTop />
            
            <Switch>
                <AppLayout  exact path="/" component={Home} />
                <DefaultLayout  exact path="/home2" component={Home2} />
                <DefaultLayout  exact path="/home3" component={Home3} />
                <DefaultLayout  exact path="/home4" component={Home4} />
                <DefaultLayout  exact path="/home5" component={Home5} />
                <AppLayout  exact path="/privacy" component={Privacy} />

                <AppLayout  component={NotFound} status={404} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
