import classes from './AppLayout.module.scss'
import { Route } from 'react-router-dom';
// import BottomMenu from './BottomMenu';



const DefaultLayout = ({ component: Component, props, ...rest }) => {
    

    return (
        <Route
            {...rest}
            render={(routeProps) => {
                
                return <Component {...routeProps} {...props} />;

            }}
        />
    );
};

export default DefaultLayout;