import React, { useEffect, useRef } from 'react';
import classes from './Home5.module.scss';

const Home5 = () => {
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(classes.visible);
                    }
                });
            },
            {
                threshold: 0.1,
                rootMargin: '0px 0px -50px 0px'
            }
        );

        // 監視対象の要素をより具体的に指定
        const targets = [
            ...document.querySelectorAll(`.${classes.sectionTitle}`),
            ...document.querySelectorAll(`.${classes.product}`),
            ...document.querySelectorAll(`.${classes.news} ul`),
            ...document.querySelectorAll(`.${classes.visionContent}`),
            ...document.querySelectorAll(`.${classes.missionContent}`)
        ];

        targets.forEach(element => {
            if (element) {
                observer.observe(element);
            }
        });

        return () => {
            targets.forEach(element => {
                if (element) {
                    observer.unobserve(element);
                }
            });
        };
    }, []);

    return (
        <div className={classes.root}>
            <header className={classes.header}>
                <img className={classes.logo} src="/react-assets/logo-icon.svg" alt="Collabo" />
                <div className={classes.catchphrase}>
                    <h2>人と人を結びつける</h2>
                </div>
                <div className={classes.bottom}>
                    コラボ株式会社
                </div>
            </header>

            <section className={classes.vision}>
                <div className={classes.sectionTitle}>
                    <h2>VISION</h2>
                </div>
                <div className={classes.visionContent}>
                    <p className={classes.visionLead}>ビジョンを掲げ、<br />ITサービスを提供していきます。</p>
                    <ul>
                        <li>難しくない。</li>
                        <li>使い方を覚える必要がない。</li>
                        <li>運営コストがかからない。</li>
                        <li>使い続ける事ができる。</li>
                        <li>ユーザーに合わせてアップデートされる。</li>
                    </ul>
                </div>
            </section>

            <section className={`${classes.mission} ${classes.gray}`}>
                <div className={classes.sectionTitle}>
                    <h2>MISSION</h2>
                </div>
                <div className={classes.missionContent}>
                    <div className={classes.missionText}>
                        <h3>頼れるパートナーとして寄り添います</h3>
                        <p>"パートナー"として最適な道を共に歩みます。</p>
                    </div>
                    <div className={classes.missionImage}>
                        <img src="/react-assets/home5_1.jpg" alt="Mission" />
                    </div>
                </div>
            </section>

            <section className={classes.mission}>
                <div className={classes.missionContent}>
                    <div className={classes.missionText}>
                        <h3>ひらめきを実現するお手伝い</h3>
                        <p>パッとひらめいたアイデアを大切に。
抽象的な想いを形にするのが
私たちのお仕事です。</p>
                    </div>
                    <div className={classes.missionImage}>
                        <img src="/react-assets/home5_2.jpg" alt="Mission" />
                    </div>
                </div>
            </section>

            <section className={classes.products}>
                <div className={classes.sectionTitle}>
                    <h2>PRODUCTS</h2>
                </div>
                <div className={classes.product}>
                    <h3>コラボタウン</h3>
                    <p>コラボタウンはB2BでマッチングできるビジネスSNSです。私たちは企業単位より人単位で深く知りつながる事で、ビッグ・ビジネスが生まれると信じています。</p>
                    <a href="https://collabo.town" target="_blank" rel="noopener noreferrer">詳細を見る</a>
                </div>
            </section>

            <section className={classes.news}>
                <div className={classes.sectionTitle}>
                    <h2>NEWS</h2>
                </div>
                <ul>
                    <li>
                        <time>2022年2月15日</time>
                        <p>collabo.townをリリースしました。</p>
                    </li>
                    <li>
                        <time>2022年3月1日</time>
                        <p>株式会社Shapeplannningと「ご予約ドットコム」を共同開発しました。</p>
                        <img src="/react-assets/home5_3.jpg" alt="Mission" />
                    </li>
                </ul>
            </section>

            <footer className={classes.footer}>
                <div className={classes.footerContent}>
                    <a href="mailto:sales@collabo.link">お問い合わせ</a><br />
                    <a href="/privacy">プライバシーポリシー</a><br />
                    <p>© 2021 collabo, Inc.</p>
                </div>
                <address>
                    〒921-8163<br />
                    石川県金沢市横川7丁目26
                </address>
            </footer>
        </div>
    );
}

export default Home5;
